@import '../../../../scss/theme-bootstrap';

// Main Navigation - sections
.header-nav-section {
  &__section {
    position: relative;
    @include breakpoint($landscape-up) {
      align-items: center;
      display: flex;
      flex-grow: 1;
      padding: 0 10px;
    }
    &:first-of-type {
      padding-#{$ldirection}: 0;
    }
    &:hover {
      .header-nav-section {
        &__section-label {
          @include breakpoint($landscape-up) {
            color: $color-black;
            text-decoration: underline;
            text-underline-offset: $gnav-underline-offset;
          }
        }
      }
    }
    .header-nav-section__trigger:checked + & {
      background-color: $color-white;
      display: block;
      @include breakpoint($landscape-up) {
        background-color: transparent;
        overflow-y: inherit;
        display: flex;
        top: auto;
        z-index: auto;
      }
      .gnav-link {
        font-size: 16px;
        @include breakpoint($landscape-up) {
          line-height: 2;
        }
      }
    }
    .site-header-formatter__trigger-reset:not(:checked) ~ div .header-nav-section__trigger:not(:checked) + & {
      display: none;
      @include breakpoint($landscape-up) {
        display: flex;
      }
    }
    &-row-link {
      position: relative;
      color: $color-black;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
      @include breakpoint($landscape-up) {
        line-height: initial;
        height: 100%;
      }
      .site-header-formatter__text-color & {
        color: inherit;
      }
      .header-nav-section__trigger:checked + .header-nav-section__section & {
        @include breakpoint($landscape-up) {
          display: block;
          margin-top: auto;
        }
      }
    }
    &-label {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      padding: 0 0 8px;
      text-decoration: none;
      width: 100%;
      @include breakpoint($landscape-up) {
        font-size: 1rem;
        width: auto;
        height: auto;
        padding: 0;
        letter-spacing: inherit;
      }
      .site-header-formatter__text-color & {
        color: inherit;
      }
      &--text {
        width: 100%;
        display: block;
        color: $color-black;
        font-family: $font--text;
        cursor: pointer;
        line-height: $gnav-mobile-link-height;
        text-decoration: none;
        @include breakpoint($landscape-up) {
          line-height: normal;
        }
        &:hover {
          color: $color-black;
          text-decoration: none;
        }
        .header-nav-section__trigger:checked ~ .header-nav-section__section & {
          text-align: center;
        }
      }
    }
  }
  &__section-toggle {
    display: none;
  }
  &__section-content {
    background-color: $color-white;
    display: none;
    height: 0;
    #{$ldirection}: 0;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    width: 100%;
    @include breakpoint($landscape-up) {
      @include transition(height 0.25s ease, opacity 0.25s ease, visibility 0.25s ease);
      @include transform(translateX(-50%));
      #{$ldirection}: 50%;
      top: $gnav-height;
      max-width: $gnav-max-width;
      z-index: $gnav-fixed-z-index;
      overflow: hidden;
      position: fixed;
      display: block;
    }
    // expanded state
    .header-nav-section__trigger:checked + .header-nav-section__section & {
      display: block;
      height: auto;
      opacity: 1;
      position: relative;
      visibility: visible;
      overflow: hidden;
      @include breakpoint($landscape-up) {
        position: fixed;
        z-index: $gnav-fixed-z-index;
        background-color: $color-white;
        overflow: visible;
      }
      .gnav-section-group > div:nth-child(1) {
        .gnav-section-formatter__content-wrapper {
          @include breakpoint($landscape-up) {
            opacity: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
        .gnav-section-formatter__link:hover ~ .gnav-section-formatter__content-wrapper {
          @include breakpoint($landscape-up) {
            opacity: 1;
          }
        }
      }
    }
    .site-header-formatter--sticky &,
    .gnav-offers-hidden & {
      @include breakpoint($landscape-up) {
        top: $navbar-height - 20px;
      }
    }
    .content-block-sitewide-banner--enabled & {
      @include breakpoint($landscape-up) {
        top: $gnav-height;
      }
    }
    .content-block-sitewide-banner--enabled.gnav-offers-hidden &,
    .content-block-sitewide-banner--enabled.site-header-formatter--sticky & {
      @include breakpoint($landscape-up) {
        top: $navbar-height + $sitewide-banner-height;
      }
    }
  }
  &__mobile-menu-icon {
    margin-#{$ldirection}: auto;
    fill: $color-black;
    opacity: 1;
    height: $gnav-icon--inline;
    width: $gnav-icon--inline;
    transform: rotate(270deg);
    @include breakpoint($landscape-up) {
      display: none;
    }
    .header-nav-section__trigger:checked + .header-nav-section__section & {
      display: none;
    }
    &:hover,
    &:active {
      fill: $color-black;
    }
  }
}

body {
  // GNAV simplified on all checkout pages, excluding the cart page.
  &#index,
  &#samples,
  &#shipping,
  &#billing,
  &#review,
  &#confirm {
    .header-nav-section {
      display: none;
    }
  }
}
